<template>
	<div class="app-body">
		<div class="home-container scroll-cont">
			<div class="home-amount-cont" >
				<div class="back-cont">
						<div class="back-list f14">
							<div class="back-list-top">
								<p v-if="info.state">
									报修单号：{{info.repair_num}}
									<span class="right f16" :style="'color:'+statusInfo[info.state]['color']">{{info.state_name}}</span>
								</p>
								<p class="mt">提交人：{{info.submitter}}</p>
								<p class="mt">提交时间：{{info.create_time}}</p>
								<p class="mt" v-if="info.audit_time">审核时间：{{info.audit_time}}</p>
								<p class="mt" v-if="info.delivery_time">发货时间：{{info.delivery_time}}</p>
								<p class="mt" v-if="info.verification_time">核销时间：{{info.verification_time}}</p>
								<div>
									<table class="">
										<tr>
											<th>设备类型</th>
											<th>设备编号</th>
											<th>报修原因</th>
											<th>状态</th>
										</tr>
										<tr v-for="(item,index) in info.cabinetInfo" v-bind:key="index">
											<td>{{item.cabinet_type}}</td>
											<td>{{item.imei}}</td>
											<td>{{item.remarks}}</td>
											<td class="text-danger">{{item.state_name}}</td>
										</tr>
									</table>
								</div>
								<p class="mt">充电宝总数：{{info.battery_total}}</p>
							</div>
						</div>

						<div class="back-list f14" >
							<!-- 运营商 -->
							<div class="back-list-top" v-if="info.bdm_state"> 
								<p class="text-lighter">审核人：{{info.bdm_name}}
									<span class="right text-color f16" v-if="info.bdm_state == 2">未审核</span>
									<span class="right text-success f16" v-if="info.bdm_state == 1">通过</span>
									<span class="right text-danger f16" v-if="info.bdm_state == 3">拒绝</span>
								</p>
								<p class="mt text-lighter" v-if="info.bdm_time">审核时间：{{info.bdm_time}}</p>
								<p class="mt text-lighter" v-if="info.bdm_state != 2">审核原因：{{info.bdm_reason}}</p>
							</div>
							<!-- 运营中心 -->
							<div class="back-list-bottom" v-if="info.op_state">
								<p class="text-lighter">审核人：{{info.op_name}} 
									<span class="right text-color f16" v-if="info.op_state == 2">未审核</span>
									<span class="right text-success f16" v-if="info.op_state == 1">通过</span>
									<span class="right text-danger f16" v-if="info.op_state == 3">拒绝</span>
								</p>
								<p class="mt text-lighter" v-if="info.op_time">审核时间：{{info.op_time}}</p>
								<p class="mt text-lighter" v-if="info.op_state != 2">审核原因：{{info.op_reason}}</p>
							</div>
							<!-- 平台 -->
							<div class="back-list-bottom" v-if="info.state != 7 && info.state!=6">
								<p class="text-lighter">审核人：蜂巢科技 
									<span class="right text-color f16" v-if="info.state == 2">未审核</span>
									<span class="right text-success f16" v-if="info.state == 4 || info.state==5 || info.state==1">通过</span>
									<span class="right text-danger f16" v-if="info.state == 3">拒绝</span>
								</p>
								<p class="mt text-lighter" v-if="info.audit_time">审核时间：{{info.audit_time}}</p>
								<p class="mt text-lighter" v-if="info.remarks">审核原因：{{info.remarks}}</p>
							</div>
						</div>
						<div class="back-list f14" v-if="info.state == 6">
							<div class="back-list-top">
								<p class="text-lighter">撤销时间：{{info.cancellation_time}}</p>
							</div>
						</div>
						<div class="back-btn-box f16" >
							<button class="back-btn btn-blue" v-if="isExist(['2'],info.state)" @click="openDialog('aduit')">审核</button>
							<button class="back-btn btn-blue" v-if="isExist(['1','5'],info.state)" @click="goLog">查看物流</button>
							<button class="back-btn btn-blue" v-if="isExist(['4','5','7'],info.state)" @click="openDialog('cancel')">核销</button>
						</div>
				</div>
			</div>
		</div>
		<van-popup v-model="showDialog" :style="{ width: '90%' , 'border-radius': '8px'}">
			<div class="vanPopup-box" >
				<div v-if="flag == 'aduit'">
					<p class="f24 title"><strong>审核报修单</strong></p>
					<div class="sel-cell-box">
						<div class="sel-cell-title"><span><i class="text-danger">*</i> 操作</span></div>
						<div class="sel-cell-value">
							<div class="sel-cell-field">
								<van-radio-group v-model="formData.state" direction="horizontal">
									<van-radio name="4" checked-color="#54c1cf">通过</van-radio>
									<van-radio name="3" checked-color="#54c1cf">拒绝</van-radio>
								</van-radio-group>
							</div>
						</div>
					</div>
					<div class="sel-cell-box" v-if="formData.state == 4">
						<div class="sel-cell-title"><span><i class="text-danger">*</i> 收货地址</span></div>
						<div class="sel-cell-value">
							<div class="sel-cell-field">
								<select class="sel-cell-input " v-model="formData.address_id" >
									<option :value="item.id" v-for="(item,index) in addressList" v-bind:key="index">{{item.contacts}}/{{item.telephone}} {{item.address}}</option>
								</select>
							</div>
						</div>
					</div>
					<div class="sel-cell-box">
						<div class="sel-cell-title"><span><i class="text-danger"></i> 审核理由</span></div>
						<div class="sel-cell-value">
							<div class="sel-cell-field">
								<textarea v-model="formData.remarks" class="sel-cell-area f14" placeholder="请输入审核理由..." ></textarea>
							</div>
						</div>
					</div>
				</div>
				<div v-if="flag == 'cancel'" style="margin:0.6rem 0;">
					<p class="f24 title"><strong>核销报修单</strong></p>
					<p class="f16 text-center">是否收到所有的退回的设备？</p>
				</div>
				<div class="vanPopup-bottom">
					<span><button class="btn btn-block btn-lg f16 btn-border" @click="showDialog = false">再想想</button></span>
					<span class="ml"><button class="btn btn-lg f16 btn-block btn-blue"  @click="handleAdd()">提交</button></span>
				</div>
			</div>
		</van-popup>
	</div>
</template>
<script>
import { Toast,PullRefresh,List,Popup,Radio,RadioGroup  } from 'vant'
import { repairReportDetail ,getRepairAddress, writeOffRepair , reviewRepair } from '@/api/device'
export default{
	components: {
		[PullRefresh.name]:PullRefresh,
		[List.name]:List,
		[Popup.name]:Popup,
		[Radio.name]:Radio,
		[RadioGroup.name]:RadioGroup,
	},
	data() {
		return {
			statusInfo:{
				2:{'name':'待审核','color':'#ff5252'},
				4:{'name':'待发货','color':'#35b0fa'},
				5:{'name':'待核销','color':'#35b0fa'},
				3:{'name':'拒绝','color':'#ff5252'},
				1:{'name':'已完成','color':'#0ad162'},
				6:{'name':'已撤销','color':'#ffcc00'},
				7:{'name':'审核中','color':'#209ef7'},
			},
			info: {},
			flag:'aduit',
			showDialog: false,
			formData: {},
			addressList: [] //公司地址列表
		}
	},
	watch: {

	},
	computed: {

    },
	mounted() {
		this.initData()
	},
	methods: {
		isExist(arr,param) {
			if(arr.indexOf(String(param)) > -1) return true
			else return false
		},
		initData() {
			let params = {}
			params.id = this.$route.query.repair_id
			repairReportDetail(params).then(res => {
				if(res.data.error_code == 1000){
					this.info = res.data.data
				}else{
					Toast(res.msg)
				}
			}).catch(error=>{
				console.log(error)
			})
			getRepairAddress(params).then(res => {
				console.log(res.data.data)
				if(res.data.error_code == 1000){
					this.addressList = res.data.data
				}else{
					Toast(res.msg)
				}
			}).catch(error=>{
				console.log(error)
			})
        },
        openDialog(flag) {
			this.flag = flag
			this.showDialog = true
        },
        handleAdd() {
			let params = {}
			if(this.flag == 'aduit') {
				if(this.formData.state) {
					params.state = this.formData.state
					if(this.formData.state == 4) {
						if(this.formData.address_id){
							params.address_id = this.formData.address_id
						}else{
							Toast('请选择地址')
							return false
						}
					}
					params.id = this.info.id
					params.remarks = this.formData.remarks
					console.log(params)
					reviewRepair(params).then(res => {
						if(res.error_code == 1000){
							Toast(res.msg)
							this.initData()
							this.showDialog = false
						}else{
							Toast(res.msg)
						}
					});
				}else{
					Toast('请选择操作')
				}
			}else if(this.flag == 'cancel'){
				params.id = this.info.id
				writeOffRepair(params).then(res => {
					if(res.error_code == 1000){
						Toast(res.msg)
						this.initData()
						this.showDialog = false
					}else{
						Toast(res.msg)
					}
				});
			}

        },
        goLog() {
			window.location.href = 'https://m.kuaidi100.com/result.jsp?nu='+this.info.logistics_num
		}
	}
}
</script>
<style lang="scss" scoped>
@import '../../assets/css/mixin';
.back-list{
	margin:0.4rem;
	background:#fff;
	border-radius:5px;
	padding:0 0.4rem;
	box-shadow:0px 1px 1px #eeeeee;
	.back-list-top{
		padding:0.4rem 0;
		border-bottom:1px solid #f5f5f5;
	}
	.back-list-bottom{
		padding:0.4rem 0;
	}
	table{
		width:100%;
		text-align:center;
		padding:0;
		font-size:0.32rem;
		margin:0.4rem 0;
		border-collapse: collapse;
		border-spacing:0;
		tr > th{
			color:#999999;
		}
		tr > td , tr > th{
			border:1px solid #dbdbdb;
			padding:10px 2px;
		}
	}
}
.back-btn-box{
	display:flex;
	margin:0.4rem;
}
.back-btn{
	border-radius:8px;
	padding:0.4rem;
	width:100%;
	box-shadow:0 1px 5px rgba(153,153,153,0.3);
	margin-right:0.4rem;
	&:last-child{
		margin-right:0;
	}
}
</style>